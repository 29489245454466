/* Profile Photo Edit Icon */
.profile-profile-photo-section {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
}

.profile-profile-container {
    margin-left: 50px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-profile-info-container {
    margin-left: 20px;
    padding: 10px; /* Add padding inside the container */
    border: 2px solid white; /* Add white border around the whole profile section */
    border-radius: 10px; /* Optional: Add rounded corners to the border */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-profile-fields-container {
    display: flex;
    text-align: center;
    width: 100%;
}

.view-listing-btn {
    align-self: flex-start; 
    margin-top: 10px; 
}

.profile-profile-photo {
    width: 100px;
    height: 100px;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    object-fit: cover;
}

.profile-profile-photo-placeholder {
    align-items: center;
    text-align: center;
    margin-bottom: -5px;
    margin-left: -10px;
    font-size: 110px !important;
}

.save-edit-profile-button{
    background-color: white;
    color: black;
    border-radius: 10px;
    justify-content: center;
    margin-left: -10px;
    margin-top: 10px;
    padding: 15px 20px;
    cursor: pointer;
}

.edit-icon-photo {
    position: absolute;
    bottom: 10px;
    color: white;
    font-size: 15px !important;
    cursor: pointer;
}
.edit-icon {
    position: relative;
    margin-left: 10px; /* Spacing between text field and icon */
    color: white;
    cursor: pointer;
    font-size: 15px !important;
}

.profile-profile-field {
    display: flex;
    color: white;
    align-items: center;
    text-align: center;
    position: relative;
    margin-left: 5px;
    width: 100%;
}

.profile-profile-field-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 15px;
    width: 100%;
}

.profile-profile-field p, .profile-profile-field h2 {
    margin: 0; /* Remove any default margin applied to paragraphs and h2 tags */
    padding: 0; /* Remove padding if any */
}

/* Reviews Section */
.reviews-section {
    max-width: 500px;
    margin: 0 auto;
    margin-top: -250px;

    text-align: center;
    color: white;
}

.reviews-tabs {
    display: flex;
    width: 100%;
}

.reviews-tabs button {
    flex: 1;
    padding: 10px;
    border: none;
    background: #444;
    color: white;
    cursor: pointer;
}

.review-box {
    position: relative;
    width: 100%;
    height: 500px;
    background: #222;
}

.coming-soon-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(221, 221, 221, 0.6);
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
@media screen and (max-width: 768px) {

    .profile-profile-container {

        margin-left: 1px;
    }

    .reviews-section {
        width: 100%; /* Full width on mobile */
        max-width: 100%; /* Allow review section to take full width */
        align-items: center;
        margin-top: 0;
    }

    .review-box {
        height: 500px; /* Adjust height for mobile */
    }
}