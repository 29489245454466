.booking-container {
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 80px;
    padding: 20px;
    background: #000000;
    color: white;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(255, 255, 255, 0.1);
  }
  
  .booking-header {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
    color: white;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
    object-fit: cover;
  }
  
  .subheader-booking {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
  }

  .subsubheader-booking {
    font-size: 18px;
  }
  
  .activity-description {
    font-size: 14px;
    color: #ccc;
    margin-bottom: 8px;
    margin-left: 1px;
}
  
  .payment-option {
    font-size: 16px;
    background: #222;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  
  .price-details, .price-total {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 8px 0;
    color: white;
  }
  
  .price-total {
    font-weight: bold;
    font-size: 18px;
  }
  
  .request-button {
    width: 100%;
    background-color: white;
    color: black;
    padding: 12px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    border: none;
    margin-top: 16px;
  }
  
  .request-button:hover {
    background-color: #cccccc;
  }
  
  .custom-time-select .MuiInputBase-root, 
  .custom-time-select .MuiInputBase-input, 
  .custom-datepicker-container .MuiInputBase-root, 
  .custom-time-select .MuiSelect-root {
      background-color: black !important; /* Set background color to black */
      border: none !important; /* Remove all borders except bottom */
      border-bottom: 1px solid white !important; /* Set uniform bottom border */
      color: white !important; /* Ensure the text inside is white */
      padding-bottom: 2px !important; /* Adjust top padding for better alignment */
      border-radius: 0;
  }

  .custom-datepicker-container .MuiInputBase-input {
    padding-bottom: 2px !important; /* Adjust top padding for better alignment */
    border-bottom: none !important
  }

  .custom-time-select .MuiInputBase-root:focus-within, 
  .custom-time-select .MuiInputBase-input:focus {
      outline: none !important;
      box-shadow: none !important;
  }

  .custom-datepicker-container .MuiOutlinedInput-notchedOutline {
    display: none !important; 
}

  .custom-time-select .MuiInputBase-root:focus-visible, 
.custom-datepicker-container .MuiInputBase-root:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

  .custom-time-select .MuiOutlinedInput-notchedOutline {
    display: none !important; /* Hide the extra border */
}

.custom-time-select .MuiSelect-icon {
    color: white !important;
}

.custom-time-select .MuiMenuItem-root {
    background-color: black !important;
    color: white !important;
}

.custom-time-select .MuiSvgIcon-root {
    color: white !important;
}


.custom-datepicker .MuiFormLabel-root, 
.custom-datepicker .MuiInputBase-input::placeholder {
    color: white !important; /* Set placeholder text color to white */
}

.custom-datepicker-container .MuiInputAdornment-root button svg {
    color: white !important; 
}

.custom-datepicker-container .MuiInputAdornment-root {
    border-right: none !important;
}

.custom-datepicker-container .MuiInputAdornment-root {
    border-right: none !important; /* Remove vertical line */
    padding-right: 0 !important; /* Ensure no padding that creates a line */
}

.custom-time-form-control, .custom-datepicker-container {
    margin-bottom: 25px !important; /* Add space between inputs */
}

.custom-datepicker-container {
    margin-top: 10px !important;
}

.custom-datepicker-container .MuiInputBase-input {
    text-align: left !important; /* Ensure text inside the date input is left aligned */
}


.custom-time-label, .MuiFormLabel-root {
    color: white !important;
}

.custom-datepicker, .custom-time-form-control {
    padding-bottom: 20px; 
}

.custom-time-form-control, .custom-datepicker-container {
    width: 100% !important; /* Force full width to match across all fields */
}

.booking-time-past-midnight {
    font-size: 13px;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-10px); 
}

.refund-warning-booking {
    font-size: 13px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.booking-error-message {
  color: red;
  text-align: center;
}
input:-webkit-autofill {
    background-color: #f8f8f8 !important; /* Match the background color of your input */
    box-shadow: 0 0 0px 1000px white inset !important; /* Remove the autofill yellow box shadow */
  }
  
  input:-webkit-autofill::first-line {
    color: transparent !important; /* Make autofilled text transparent */
  }
  
  .StripeElement {
    padding-left: 10px !important; /* Adjust padding if necessary */
  }