.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 13px;
    position: relative;
  }

  .footer-socials {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .footer-socials a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: white;
    color: black;
    border-radius: 50%;
    font-size: 15px;
    text-decoration: none;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .footer-top-border {
    border-top: 1px solid white;
    margin-bottom: 10px;
  }
  
  .footer-links {
    margin-bottom: 8px;
    margin-top: 3px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-links span {
    color: white;
    margin: 0 5px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    font-size: 11px;
    padding-top: 3px;
  }
  