/* General Page Styles */
.listing-page {
    background-color: black;
    color: white;
  }
  
  .image-gallery {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1500px;
    position: relative;
  }
  
  /* Individual image styling */
  .gallery-image {
    width: 100%; /* Ensure the image fills the container */
    aspect-ratio: 2/3;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners */
    object-fit: cover; /* Ensure the image doesn't distort */
  }

  .image-container {
    flex: 1 1 calc(30% - 50px);
    position: relative; /* Allow button to be positioned relative to this container */
    width: 30%; /* Allow the image container to take up 30% of the space */
    max-width: 400px; /* Set a maximum width for each image */
    min-width: 225px;
    flex-shrink: 0;
  }
  

  .close-modal {
    color: black; /* White text for the close button */
    font-size: 2rem;
  }

  .close-modal-container {
    position: absolute;
    background-color: white;
    cursor: pointer;
    border-radius: 10%; 
    width: 40px;
    height: 40px;
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    top: 10px; /* Distance from the top edge */
    right: 10px; /* Distance from the right edge */
    z-index: 2;
  }
  
  .close-modal-container:hover {
    background-color: black;
    .close-modal {
      color: white;
    }
  }

  .gallery-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 100px;
    height: 100px;
    font-size: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}


.gallery-arrow.left {
    left: 10%;
    width: 50px;
    height: 50px;
}

.gallery-arrow.right {
    right: 10%;
    width: 50px;
    height: 50px;
}
  
  /* Modal */
  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-image {
    max-width: auto;
    max-height: 80vh;
    border-radius: 10px;
  }

  .modal-image-container {
    position: relative; /* This ensures the close button is positioned relative to the image container */
    height: auto;
  }



  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    gap: 20px; /* Optional: Adds spacing between the two sections */
  }

  .profile-container {
    width: calc(90%);
    max-width: 1200px; /* Matches the width constraint of .image-gallery */

  }
  
  /* Profile Section */
  .profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    overflow: hidden;
  }
  
  .profile-info h2 {
    margin: 0;
    font-size: 1.7rem;
  }
  
  .profile-info p {
    margin: 0;
    font-size: 1.0rem;
  }
  
  /* Tags */
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tag {
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
  }
  
  /* Bio */
  .bio {
    margin-bottom: 40px;
    font-size: 1.2rem;
    line-height: 1.5;
    width: 88%;
  }

  .show-all-button {
    position: absolute;
    bottom: 25px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 0.9rem;
    right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    bottom: 1.5%;
  }

  .show-all-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
  }

  .show-all-icon {
    margin-bottom: 1px;
  }
 
  
  /* Sticky Booking Section */
  .sticky-booking {
    position: sticky;
    bottom: 10px;
    background-color: rgb(221, 221, 221);
    color: black;
    border: 1px solid black;
    display: flex;
    min-width: 760px;
    max-width: 1300px;
    border-radius: 20px;
    padding: 10px 1%;
    box-sizing: border-box;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  
  .hourly-price {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1%;
    padding-top: 0.2%;
    margin-bottom: 1%;
  }


  
  .continue-container {
    background-color: black;
    display: flex;
    border-radius: 15px;
    margin-left: 15%;
    justify-content: center; /* Ensure proper alignment */
    align-items: center; /* Vertically center the button */
    }
  
  .continue {
    background-color: transparent;
    color: white;
    font-weight: bold;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 20px 60px;
    border-radius: 50px;
    border: none;
    text-decoration: none;
  }
  
  .continue:hover {
    text-decoration: underline;
  }

  .enable-payments-warning {
    text-align: center;
    color: red;
  }

  .enable-listing-warning {
    text-align: center;
    color: white;
  }

  .setup-stripe-button {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    background-color: white;
    font-weight: bold;
    color: black;
    border-radius: 10px;
    padding: 10px 10px;
  }

  .setup-stripe-button:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }

  .mobile-gallery {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
  }

  .mobile-gallery::-webkit-scrollbar {
    display: none;
  }
  
  .mobile-image-container {
    position: relative;
    flex: 0 0 auto;
    width: 100%; /* Full width for each image */
    scroll-snap-align: center;
  }
  
  .mobile-gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .image-indicator {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6); /* High-opacity background */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: 768px) {

    .sticky-booking {
      min-width: 360px;
      max-width: 700px;
      border-radius: 5px;
      border: none;
      bottom: 0px;
      padding: 5px 1%;
      box-sizing: border-box;
    }

    .hourly-price {
      font-size: 1.5rem;

    }
    .profile-photo {
      width: 80px;
      height: 80px;
    }
    .profile-info h2 {
      font-size: 1.5rem;
    }
    
    .profile-info p {
      font-size: 0.9rem;
    }
    .bio {
      font-size: 1.1rem;
      padding-bottom: 20px;
    }

    .continue-container {
      border-radius: 15px;
      margin-left: 15%;
      }
    
    .continue {
      background-color: transparent;
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 10px 30px;
      border-radius: 50px;
      border: none;
    }

}
  