.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.sign-in-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.login-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

.login-input:focus {
    border-color: #007bff;
}

.terms-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

.terms-text a {
    color: #007bff;
    text-decoration: none;
}

.submission-button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 10px;
}

.submission-button:hover {
    background: #0056b3;
}

.submission-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-messages {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.error {
    margin: 5px 0;
}
