@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

/* Navbar Styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: black;
    color: white;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

/* Left Section: Logo and Title */
.navbar-left {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    gap: 10px;
}

.navbar-title a:visited{
    text-decoration: none;
    color: inherit;
}

.navbar-left a {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Keeps the text color consistent with the surrounding text */
  }
  
  /* Remove underline and color change for visited links */
  .navbar-left a:visited {
    text-decoration: none; /* Removes underline on visited link */
    color: inherit; /* Keeps color consistent for visited link */
  }
  
  /* Ensure no underline on hover */
  .navbar-left a:hover {
    text-decoration: none; /* Ensures no underline on hover */
    color: inherit; /* Keeps color consistent on hover */
  }


.navbar-logo {
    height: 40px;
    width: auto;
}

.navbar-title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
}

/* Right Section: Links */
.navbar-links {
    display: flex;
    gap: 30px;
    list-style: none;
    margin: 0;
    padding-right: 50px;
}

.navbar-links a,
.navbar-links button { /* Apply styles to both links and buttons */
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    cursor: pointer; /* Add pointer cursor for buttons */
    transition: color 0.3s ease;
    padding: 0; /* Reset padding */
}

.navbar-links button {
    margin-bottom: 1px;
}

.navbar-links a:hover,
.navbar-links button:hover { /* Apply hover effect to both */
    color: #d1d0d0;
}


/* Special Button for 'Become an ActivityBuddy' */
.navbar .navbar-button {
    padding: 8px 16px;
    background-color: white;
    color: black !important;
    border-radius: 5px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-button:hover {
    background-color: #d1d0d0;
    color: black;
    text-decoration: underline;
}


/* Profile Dropdown Styling */
.profile-menu {
    position: relative;
    display: flex;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-label {
    margin-top: 1px;
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Dropdown Icon */
.dropdown-icon {
    margin-top: 1px;
    font-size: 0.8rem;
    color: white;
    transition: transform 0.2s ease;
}

.dropdown-icon.active {
    transform: rotate(180deg);
    margin-bottom: 2px;
}

/* Dropdown Menu (Web) */
.dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: black;
    border: 1px solid #333;
    padding: 10px 0;
    list-style: none;
    text-align: left;
    z-index: 1000;
    border-radius: 5px;
    width: 150px;
    display: none; /* Default hidden */
    opacity: 0;    /* Add transition for smoother visibility toggle */
    visibility: hidden;
}

.dropdown-menu.always-visible,
.dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu li {
    padding: 10px 20px;
}

.dropdown-menu li a {
    display: block;
}

.nav-unread-indicator-sub {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 6px;
    top: 141px;
}

.nav-unread-indicator-main {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: 69px;
    top: 5px;
}

.dropdown-menu li:hover {
    background-color: #333;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {

    .nav-unread-indicator-sub {
        position: absolute;
        left: 139px;
        top: 112px;
    }
    
    .nav-unread-indicator-main {
        position: absolute;
        right: 230px;
        top: 6px;
    }

    /* Profile Menu */
    .profile-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default; /* Make unclickable */
        width: 100%;
    }

    /* Align Profile with Other Options */
    .profile-label {
        font-size: 1.2rem;
        font-weight: 500;
        color: white; /* Match the style of other menu items */
        display: flex;
        margin-left: 12px;
        align-items: center;
    }

    /* Always Show Dropdown */
    .dropdown-menu {
        position: relative;
        display: block; /* Always visible */
        background: none;
        padding: 0;
        border: none;
        width: 100%;
        margin: -25px 0 0 0; 
    }

    /* Suboptions Positioned Below */
    .profile-menu .dropdown-menu li {
        text-align: center;
    }

    .profile-menu .dropdown-menu li a,
    .profile-menu .dropdown-menu li button {
        font-size: 1rem !important; /* Force the font size */
        font-weight: 250;
    }

    .navbar {
        max-width: 200px;
    }

    .navbar-logo {
        height: 30px; /* Make logo smaller */
        margin-left: -5px;
    }

    .navbar-title {
        font-size: 1.2rem; /* Make title smaller */
    }

    /* Navbar links styling */
    .navbar-links {
        display: none;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background-color: black;
        position: absolute;
        top: 50px;
        left: 50%; /* Center the links */
        transform: translateX(-50%); /* Adjust to perfectly center */
        padding-top: 20px;
        padding-bottom: 20px;
        z-index: 10;
    }

    .navbar-links.active {
        display: flex;
        z-index: 100;
    }

    .navbar-links a,
    .navbar-links button {
        color: white;
        font-weight: 500;
        font-size: 1.2rem;
        text-decoration: none;
    }

    .navbar-links a:hover {
        color: #d1d0d0;
    }

    /* Make the navbar-button align with the links on mobile */
    .navbar-button {
        width: 100%;
        margin-top: 20px;
    }

    /* Hamburger icon appears on smaller screens */
    .navbar .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 25px;
        margin-top: 8px;
        cursor: pointer;
        z-index: 100; /* Make sure it's on top of other elements */
        position: absolute; /* Ensure it's above other content */
        top: 10px;
        right: 20px; /* Adjust position for mobile */
        outline: none;
        border: none;
    }
    
    /* Styling for each line of the hamburger */
    .navbar .hamburger .line {
        background-color: white;  /* Ensure visibility */
        height: 4px;              /* Define line thickness */
        width: 100%;              /* Ensure lines take full width */
        border-radius: 2px;       /* Make the lines rounded */
        transition: background-color 0.3s ease; /* Smooth transition */
    }

    .navbar .hamburger:focus,
    .navbar .hamburger:active {
        outline: none;
        box-shadow: none;
        background-color: transparent; /* Ensure no background color change */
    }

    .navbar .hamburger div:focus,
    .navbar .hamburger div:active {
        outline: none;
        box-shadow: none;
    }
}
