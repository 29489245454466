.support-container {
  background-color: #000;
  color: #fff;
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  border-radius: 10px;
  text-align: center;
}

.support-title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.support-helper-text {
  font-size: 1rem;
  margin-bottom: 20px;
}

.support-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers form items horizontally */
  align-self: right;
  gap: 25px;

}

.support-type {
  display: flex;
  gap: 15px;
}

.support-type label {
  font-size: 1rem;
  cursor: pointer;
}



.support-input,
.support-textarea {
  width: 100%;
  /* Full width for better alignment */
  max-width: 500px;
  /* Restrict max width */
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #000000;
  color: #fff;
}

.support-input::placeholder,
.support-textarea::placeholder {
  color: #aaa;
}

.support-textarea {
  height: 100px;
}

.support-input {
  text-align: left;
}

.support-input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #00000000;
}

.support-error {
  color: red;
  font-size: 0.9rem;
  margin-top: -10px;
  /* Adjust spacing to bring closer to input fields */
  margin-bottom: 10px;
  /* Add spacing from the submit button */
  text-align: center;
  /* Align error text with the form */
}

.support-submit {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #fff;
  /* White background */
  color: #000;
  /* Black text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
  /* Auto width for a tighter fit */
  text-align: center;
}

.support-submit:hover {
  background-color: #ddd;
}

.support-submit:disabled {
  background-color: #cccccc;
  /* Greyed-out background */
  cursor: not-allowed;
  /* Prevent hover effect and show a disabled cursor */
  opacity: 0.7;
  /* Slightly dim to indicate disabled state */
  pointer-events: none;
  /* Fully disables hover or click events */
}

.support-thankyou {
  font-size: 1rem;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  /* Safari Mobile hack */
  @supports (-webkit-touch-callout: none) {
    .support-type input[type="radio"] {
      position: relative;
      left: 11px; /* Fine-tune alignment specifically for Safari Mobile */
    }
  }
}