
/* General Container */
.about-container {
  line-height: 1.6;
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  color: black;
}

/* Header Section */
.about-header {
  text-align: center;
  margin-bottom: 40px;
}

.about-header h1 {
  font-size: 2.5rem;
  color: white;
}

.join-movement-container {
    background-color: rgba(255, 255, 255, 0.923);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
    border-radius: 10px;
}

.join-movement-text{
    color: black;
    text-align: center;
    font-size: 1.1rem;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.join-movement-header {
    color: black;
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;

}

.button-container {
    display: flex;
    padding-bottom: 25px;
    flex-direction: row;
    justify-content: space-between;
}

.about-header p {
  font-size: 1.2rem;
  color: white;
}

/* Section Styling */
.about-section {
  margin-bottom: 40px;
}

.about-section h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: white;
}

.about-section p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: white;
}

.about-section ul {
  padding-left: 20px;
  margin-bottom: 15px;
  color: white;
}

.about-section ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: white;
}

.about-section ul li strong {
  color: white;
}

/* Call to Action Section */
.call-to-action {
  text-align: center;
}

.call-to-action p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.waitlist-button {
  background-color: black;
  align-self: center;
  color: white;
  padding: 10px 20px;
  font-size: 1.25rem;
  margin-right: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.become-button {
    background-color: black;
    align-self: center;
    color: white;
    padding: 10px 20px;
    font-size: 1.25rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }


/* Responsive Design */
@media (max-width: 768px) {
  .about-header h1 {
    font-size: 2rem;
  }

  .about-header p {
    font-size: 1rem;
  }

  .about-section h2 {
    font-size: 1.5rem;
  }


  .join-movement-text {
    margin-left: 10px;
    margin-right: 10px;
  }

  .join-movement-header {
    font-size: 1.5rem;
}

.join-movement-container {
    padding: 10px 0px;
}

.waitlist-button {

    margin-top: 0px;
    margin-right: 0px;
    width: 100%;

  }

  .become-button {

    margin-top: 10px;
  }

  .button-container {
    flex-direction: column;
  }
}
