.activities-container {
    background-color: black;
    color: white;
    min-height: 100vh;
    padding: 20px;
}

.activities-status-header {
    background-color: #333;
    color: #ccc;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
}

.activities-tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.activities-tab {
    background: none;
    border: 2px solid white;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    margin: 0 10px;
    transition: background 0.3s ease;
}

.activities-tab.active {
    background: white;
    color: black;
}

.activities-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.activities-activity-card {
    display: flex;
    justify-content: space-between; /* Aligns elements to the left and right */
    align-items: center;
    background-color: #1a1a1a;
    width: 80%;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 10px;
}

.activities-activity-card:hover {
    background-color: #333;
}

.activities-profile-pic {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}
.my-activities-header {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    padding: 15px 0;
    background-color: black;
    color: white;
}

.activities-profile-pic-placeholder {
    font-size: 70px !important;
    border-radius: 50%;
    margin-right: 15px;
}

.activities-activity-info {
    display: flex;
    flex-direction: column;
    flex: 1; /* Takes up available space, ensuring alignment */
}

.activities-activity-info p {
    margin: 5px 0; /* Adds a small vertical margin between paragraphs */
}

.activities-name {
    font-size: 18px;
    font-weight: bold;
}

.activities-status {
    font-style: italic;
}

.activities-time-info {
    text-align: right; /* Aligns the date and time to the right */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
}

.activities-time-info p {
    margin: 5px 0; /* Adds a small vertical margin between paragraphs */
}

.activities-date, .activities-time {
    font-size: 16px;
}

@media screen and (max-width: 768px) {

    .activities-activity-card {
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;
    }

    .activities-status-header {
        padding: 10px;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .activities-profile-pic {
        margin-right: 5px;
    }
    
    .activities-name {
        font-size: 16px;
    }

    .activities-status {
        font-size: 15px;
    }

    .activities-date, .activities-time {
        font-size: 14px;
    }
    
    .activities-profile-pic-placeholder {
        margin-right: 5px;
    }

    .activities-time-info {
        margin-top: 2px;
    }

    .activities-profile-pic-placeholder {
        margin-left: -8px;
    }

    .activities-profile-pic {
        margin-left: -4px;
    }

}
