.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.846);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: #000000;
    border: 1px solid white;
    padding: 80px 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.error {
    color: red;
    font-size: 0.9rem;
}

.terms-container {
    display: flex;
    color: white;
    width: 68%;
    margin-bottom: 10px;
    
}

.terms-text {
    font-size: 0.8rem;
    text-align: start;
}

.sign-in-header {
    color: white;
    margin-top: -10px;
    margin-bottom: 50px;
}

.submission-button {
    background-color: white;
    color: black;
    font-size: 1.2rem;
    margin-bottom: 10px;
    width: 66%;
    align-self: center;
    border-radius: 5px;
    font-weight: bold;
    border: solid 1px;
    padding: 12px 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

.login-input {
    background-color: transparent;
    border: none;
    margin-bottom: 60px;
    margin-top: 10px;
    border-bottom: 0.01mm solid white;
    text-align: center; 
    width: 60%;
    color: white;
    font-size: 1rem;
    outline: none;
    border-radius: 0;
  
  }

  .forgot-password-login{
    background: none;
    padding: 0;
    border: none;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .password-reset-text {
    color: green;
  }
  
  /* Placeholder styling for text inputs */
  .login-input::placeholder {
    color: white;
    opacity: 1;
  }
  
  .login-input:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: black;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #00000000;
  }


  @media screen and (max-width: 768px) {
    .modal {
        width: 85%;
    }

    .login-input {
        margin-bottom: 20px;
        margin-top: 10px;
        width: 80%;
        color: white;
        font-size: 0.9rem;
        outline: none;
      
      }

      .terms-container {
        width: 85%;
      }

      .error {
        font-size: 0.8rem;
      }

      .sign-in-header {
        color: white;
        margin-top: -10px;
        margin-bottom: 25px;
    }

  }