
* {
    font-family: 'Roboto', sans-serif !important;
  }
/* Hero Section */
.hero {
    position: relative;
    width: 100%;
    height: auto; /* This ensures the hero section stretches */
    color: white; /* Make text white */
    text-align: center; /* Center-align text */
    padding-top: 50px; /* Adjust for spacing above text */
    margin-bottom: 0; 
  }
  
  .hero-content {
    z-index: 2; /* Ensure the text sits above the image */
    position: relative;
  }

  
  h1 {
    text-shadow: 1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey;
  }
  
  .hero h1 {
    font-size: 3rem;
    position: relative;
    margin: 0 auto;
    max-width: 700px;
    margin-bottom: 3%;
  }
  


  .hero-subtext {
    font-size: 0.9rem;
    padding-top: 0.5%;
    padding-bottom: 1%;
    max-width: 500px;
    margin: 0 auto;

  }

  .hero-subheader{
    font-size: 1.4rem;
    text-shadow: 1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey;
    margin: 0 auto;
    max-width: 700px;
    padding-bottom: 4%;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .cta-button {
    padding: 15px 30px;
    background-color: white !important;
    color: black !important;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .cta-button:hover {
    background-color: #d1d0d0;
  }
  

  
  /* Image Section below the Hero */
  .image-section {
    padding: 5px 0;
  }
  
  .image-section img {
    width: 50%;
  }

  /* Activity Section */
  .carousel-container {
    position: relative;
    width: 80%;
    padding: 20px 0;
    max-width: 1200px;
    background-color: black;
    margin: 0 auto;
  }
  .activity-section {
    text-align: center; /* Centers text horizontally */
  }
  .activity-header {
    color: white;
    font-size: 2rem; /* Adjust font size as needed */
    margin-bottom: 2%; /* Space between the header and body text */
  }
  .activity-body {
    color: white;
    font-size: 1.2rem; /* Adjust font size as needed */
    margin: 0 auto;
    max-width: 700px; /* Optional: Set a max-width for better text alignment */
    padding: 0 10px; /* Optional: Padding for better spacing */
    margin-bottom: 2%;
  }
/* Cards */
.activity-card {
  background: #f0f0f0;
  color: black; /* Change text color to contrast with the background */
  padding-left: 5%;
  padding-right: 5%;
  max-width: 175px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;


  /* Ensure the cards don't take up full width */
}


.refund-notice {
  color: white;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.activity-card:last-child {
  margin-right: 0; /* Remove margin on the last card */
}

.slick-slide {
	padding: 0 40px;
	box-sizing: border-box;
}

/* the parent */
.slick-list {
  margin: 0 -40px;
}

.slick-slider {
  overflow: hidden;
}



.emoji-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  font-size: 2.5rem;
}

.activity-card h3 {
  margin: 0 0 10px;
  font-size: 1rem;
}

.activity-card p {
  margin: 0;

  font-size: 0.8rem;
  text-align: center;
}

.activity-card-row {
  display: flex;
  width: 100%; /* Ensure the row spans the full width */
}
  
  /* Arrows styling */
  .carousel-arrow {
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .carousel-arrow.left {
    left: 0px;
  }
  
  .carousel-arrow.right {
    right: 0px;
  }

  /* Filter Section */
.filters {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
}

.filter-modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

/* Filter Container Styling */
.filter-container {
  background-color: black;
  padding: 25px;
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 0px;
  flex-wrap: wrap;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.filter-item {
  flex: 1 1 200px;  /* Each filter item takes up at least 200px and will grow if needed */
  min-width: 200px;  /* Prevent items from shrinking too much */
}

/* Input Fields Styling */
.MuiFormControl-root {
  background-color: white;
  border-radius: 5px;
}

/* Price Slider */
.MuiSlider-root {
  color: white;
}

.MuiSlider-thumb {
  background-color: #fff;
}

/* Activity Autocomplete */
.MuiAutocomplete-root {
  background-color: white;
  border-radius: 5px;
}
  


  .slick-prev, .slick-next {
    display: none; /* Hide the default arrows */
  }
  
/* Container for the FormControl */
.location-select {
  background-color: black; /* Background of the dropdown */
  color: white; /* Text color for selected value */
}

.location-select .MuiOutlinedInput-root {
  height: 65px;

  background-color: black;
  border-color: white;
  color: white;
}

.location-select .MuiOutlinedInput-root .MuiSelect-select {
  color: white; /* Selected text color */
}

.location-select .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Always white border */
}

.location-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color on hover */
}

.location-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when focused */
}

.location-select .MuiSelect-icon {
  color: white;
}

.gender-filter {
  background-color: black; /* Background of the dropdown */
  color: white; /* Text color for selected value */
}

.gender-filter .MuiOutlinedInput-root {
  height: 40px;
  width: 50%;
  margin: 0 auto;
  background-color: black;
  border-color: white;
  color: white;
}

.gender-filter.MuiOutlinedInput-root .MuiSelect-select {
  color: white; /* Selected text color */
}

.gender-filter .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Always white border */
}

.gender-filter .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color on hover */
}

.gender-filter .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when focused */
}

.gender-filter .MuiSelect-icon {
  color: white;
}

.filters-modal-header {
  font-size: 1.5rem !important;
}

/* Dropdown arrow */
.location-dropdown-icon {
  color: white; /* Color of the dropdown arrow */
}

/* Label (placeholder text) */
.location-label {
  color: white !important;
}

.gender-label {
  color: white !important;
  margin-left: 25% !important;
}

/* Price Label Styling */
.price-label {
  color: white; /* Label text color */
  font-weight: bold; /* Optional: Make the label bold */
  margin-right: 35% !important;
  margin-bottom: 0% !important;
  font-size: 0.75rem !important;
}

.category-label {
  color: white; /* Label text color */
  font-weight: bold; /* Optional: Make the label bold */
  margin-right: 37% !important;
  margin-bottom: 0% !important;
  margin-top: 1.5% !important;
  font-size: 0.75rem !important;
}

.age-label {
  color: white; /* Label text color */
  font-weight: bold; /* Optional: Make the label bold */
  margin-bottom: 0 !important;
  font-size: 0.8rem !important;
}

.price-slider {
  color: white;
  margin-bottom: 0 !important;
  width: 49% !important;
  margin: 0 auto;
}

.MuiSlider-markLabel {
  color: white !important; /* Optional: If you also want to adjust the mark labels */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(163, 163, 163, 0.5); /* Semi-transparent gray */
  z-index: 999; /* Ensure it's above other content */
}

.category-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkbox-row {
  display: flex;
  align-items: center; /* Align checkbox and label vertically */
  margin-left: 25%;
}

.checkbox-label {
  margin-left: 8px;
}

.MuiCheckbox-root {
  color: white !important; /* Ensures the checkbox is white */
}

.age-slider {
  color: white;
  margin-bottom: 0 !important;
  margin: 0 auto;
}

/* Slider Rail */
.MuiSlider-rail {
  background-color: #888 !important; /* Darker rail color */
}

/* Slider Track */
.MuiSlider-track {
  background-color: white !important; /* White track */
}

/* Slider Thumb */
.MuiSlider-thumb {
  background-color: white !important; /* White thumb */
  border: 2px solid white !important; /* Optional: Add border */
}
.MuiSlider-mark {
  color: white; /* White mark dots */
}

.MuiSlider-markLabel {
  color: white; /* White text for marks */
}

/* Slider Value Label */
.MuiSlider-valueLabel {
  background-color: black !important; /* Black background for value label */
  color: white !important; /* White text */
  top: -10px !important; /* Adjust vertical position */
}

.open-filters-button {
  color: black;
  background: white;
  padding: 10px 30px;
  border-radius: 20px;
  height: 80%;
  margin-top: 1.2%;
  cursor: pointer;
}

.apply-filters-button {
  color: black;
  background: white;
  padding: 10px 30px;
  border-radius: 20px;
  height: 80%;
  width: 25%;
  margin: 0 auto;
  margin-top: 1.2%;
  cursor: pointer;
}

.open-filters-button:hover {
  background: rgb(228, 228, 228);
}

/* Category Filter - Autocomplete TextField Styles */
.activities-textfield {
  width: 100%; /* Ensures full width of the container */
}

.activities-textfield .MuiInputBase-root {
  color: white;
  background-color: black;
  border-color: white;
}

.activities-textfield .MuiInputLabel-root {
  color: white;
  border-color: white;
}

.activities-textfield .MuiOutlinedInput-root fieldset {
  border-color: white; /* Normal border color */
}

.activities-textfield .MuiOutlinedInput-root:hover fieldset {
  border-color: white; /* Border color on hover */
}

.activities-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: white; /* Border color on focus */
}

.activities-textfield .MuiChip-root {
  background-color: white; /* Selected chip background */
  color: black; /* Chip text color */
  font-weight: bold; /* Bold text inside chip */
}

.activities-textfield .MuiChip-root.MuiChip-focused {
  background-color: white; /* Chip background when focused */
  color: black; /* Chip text color when focused */
}

.activities-textfield .MuiChip-root.MuiChip-selected {
  background-color: white; /* Chip background when selected */
  color: black; /* Chip text color when selected */
}

.activities-textfield .MuiChip-root:hover {
  background-color: white; /* Chip background when hovered */
  color: black; /* Chip text color when hovered */
}

.activities-textfield .MuiInputBase-root {
  height: 65px;
  color: white; 
  background-color: black; 
  border-color: white;
}

.activities-textfield .MuiAutocomplete-popupIndicator,
.activities-textfield .MuiAutocomplete-clearIndicator {
  color: white; /* Dropdown and clear button icon color */
}



.user-cards-section {
  position: relative;
  padding: 20px 20px;
  background-color: black;
  display: flex; /* Use flexbox to center the grid */
  justify-content: center; /* Centers the grid horizontally */
}

.coming-soon-parent {
  position: relative;
}

.user-cards-section, .filters {
  position: relative;
  z-index: 1; /* Ensure these are behind the overlay */
}

.coming-soon-parent {
  position: relative;
}

.user-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  width: 100%;
  max-width: 1300px;
  justify-content: center;
  align-self: center;
  z-index: 1;
  margin: 0 auto; /* Center the grid horizontally */
  box-sizing: border-box;
}

.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  border-color: black;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  text-align: center;
}



.user-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.user-info {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  padding: 10px 15px;
  background: #fff;
}

.user-name {
  font-size: 0.9rem;
  padding-left: 5px;
  font-weight: bold !important;
  color: black;
}

.user-age {
  font-size: 0.9rem;
  color: black;
}

.user-price {
  margin-left: auto;
  font-size: 0.9rem;
  padding-right: 5px;
  color: black;
}


body {
  background-color: black; /* Ensure consistent background color */
  margin: 0; /* Reset body margin */
  overflow-x: hidden;
}

.how-it-works-link {
  cursor: pointer;
  text-decoration: underline;
  color: white;
  margin-top: -2%;
  margin-bottom: 80px;
}

.activity-categories-header {
  color: white;
}

.home-more-locations-text{
  font-size: 0.7rem;
  align-self: center;
  text-align: center;
  padding: 0 0 !important;
  margin: 9px 0 !important;
}


@media screen and (max-width: 768px) {
  .hero h1 {
    font-size: 1.6rem;
    padding-bottom: 10px;
    margin-top: -15px;
    max-width: 350px;

  }

  .refund-notice {
    font-size: 0.9rem;
    width: 90%;
  }

  .how-it-works-link {
    margin-top: -5px;
    margin-bottom: 50px;
  }

  .age-label {
    margin-top: -15px !important;
  }
  .hero {
    padding-top: 40px;
    background-position: center center; 
  }
  .hero-subheader {
    font-size: 0.93rem;
    max-width: 300px;

  }
  .hero-subtext {
    font-size: 0.8rem;
    max-width: 300px;

  }
  .cta-buttons {
    display: flex;
    flex-direction: column; 
    align-items: center;

    padding: 1px;
    gap: 10px; /* Add space between buttons */
  }
  .cta-button {
    font-size: 0.9rem; /* Smaller buttons */
    padding: 10px 2px;
    width: 75%;
    align-self: center;
  }

  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 375px;
    background-color: black;
    margin: 0 auto;
  }
  .activity-card {
    padding-left: 3px;
    padding-right: 1px;
  
    padding-top: 10px;
    padding-bottom: 10px;
  }

  
  .activity-card h3 {
    margin: 0 0 1px;
    font-size: 0.8rem;
    min-width: 100px;

    text-align: center;
  }
  
  .activity-card p {
    margin: 0;
    font-size: 0.6rem;
    min-width: 100px;
    text-align: center;
  }

  .activity-categories-header {
    margin-bottom: -1px;
  }


  .activity-header {
    font-size: 1.3rem;
  }

  .activity-body {
    font-size: 0.9rem;
    max-width: 300px;
  }

  .emoji-row {
    font-size: 0.9rem; /* Reduce emoji size */
  }

  .slick-slide {
    padding: 0 15px;
    padding-right: 25px;
    box-sizing: border-box;
  }
  
  /* the parent */
  .slick-list {
    margin: 0 -15px;
  }


  .filter-container {
    min-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    align-self: center;

  }
  
  .filters {

    gap: 10px;
    max-width: 375px;
    margin: 0 auto;

  }

  .open-filters-button {
    color: black;
    background: white;
    padding: 10px 20px;
    border-radius: 20px;
    margin-top: 3%;
    cursor: pointer;
  }

  .filter-modal {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    overflow-y: auto;
    padding: 20px;
    max-height: 100%;
    width: 80%;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000;
  }

  .filter-item {
    flex: 60px;  /* Each filter item takes up at least 200px and will grow if needed */
    width: 100%;
    align-items: center;
    align-self: center;
  }

  .apply-filters-button {
    width: 50%;
  }


  .location-select .MuiOutlinedInput-root .MuiSelect-select {
    font-size: 0.8rem;
    max-height: 20px;

  }

  .location-select .MuiOutlinedInput-root {
    height: 58px;
  }


  .location-select .MuiInputLabel-root {
    font-size: 0.9rem
  }

  .location-select .MuiInputBase-root {
    width: 100%;
  }


  .price-slider {
    width: 69% !important;
  }
  .desktop-only {
    display: none;
  }

  .age-slider {
    width: 69% !important;
  }

  .gender-filter .MuiOutlinedInput-root {
    width: 70%;
    background-color: black;
    border-color: white;
    color: white;
  }

  .gender-label {
    margin-left: 15% !important;
  }
  
  .price-label {
    margin-left: -5% !important;
  }

  .category-label {
    margin-left: -5% !important;
  }

  .checkbox-row {
    margin-left: 16.5% !important;
  }

  .user-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

  }

  .activities-textfield .MuiChip-root {
    font-size: 0.6rem; /* Smaller font size inside the chip */
  }

  .activities-textfield .MuiAutocomplete-tag {
    font-size: 0.6rem; /* Smaller font size for the count (e.g., +7) */
  }

  .activities-textfield .MuiInputBase-root {
    height: 58px;
  }

  .activities-textfield .MuiInputLabel-root {
    font-size: 0.9rem;
  }



  .user-cards-section {
    padding: 10px 10px;
  }
  /* .user-card {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 20px;
    width: 100%;
    max-height: 250px;
    min-height: 250px;
    max-width: 150px;
    min-width: 150px;
    text-align: center;
  } */

  .user-card {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 250px;
    min-width: 175px;
  }

  .user-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  
  .user-info {
    font-size: 0.8rem;
    padding: 5px 5px;
  }
  
  .user-name {
    font-size: 0.8rem;
  }
  
  .user-age {
    font-size: 0.8rem;
  }
  
  .user-price {
    font-size: 0.8rem;

  }
}
  
@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}