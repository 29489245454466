.how-it-works-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .how-it-works-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  .how-it-works-close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    color: black;
    cursor: pointer;
  }
  
  
  .how-it-works-step-image {
    max-height: 425px;
    max-width: 200px;
    height: auto;
    object-fit: contain;
    margin: 1rem 0;
  }
  


  .how-it-works-steps-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem;
}

.how-it-works-modal-main-header {
  text-align: center;
  font-size: 1.8rem;
}

.how-it-works-step {
    text-align: center;
}

@media screen and (max-width: 768px) {


  .how-it-works-modal {
    background-color: white;
    padding: 0.55rem;
    border-radius: 8px;
    padding-top: 2rem;
    width: 100%;
    max-width: 600px;
    position: relative;
  }

  .how-it-works-close-modal {
    top: 30px;
  }
}

  