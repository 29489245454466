.message-thread {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #cecece;
    position: sticky;
    top: 0;
    padding: 30px 10px;
    z-index: 10;
}

.message-back-button {
    background: none;
    color: black;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    margin-left: 10px !important;
}

.message-header-first-name {
    color: black;
    font-weight: bold;
    font-size: 1.1rem;
}

.header-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.message-profile-pic {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-bottom: 5px;
    object-fit: cover;
    z-index: 11;
}

.message-profile-pic-placeholder {
    font-size: 60px !important;
    color: black;
    margin-bottom: 3px;
    border-radius: 50%;
}

.message-body {
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    max-width: 100%;
    padding: 10px;
    background-color: #000000;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
}

.message-time {
    font-size: 0.75rem;
    color: #888;
    z-index: 2; /* Ensure the timestamp is above other elements */
}

.message.received .message-time {
    margin-left: 15px;
  }
  
.message.sent .message-time {
    margin-right: 12px; /* Adjust to position the date correctly for sent messages */

  }

.message-content {
    padding: 10px 30px;
    margin-left: 5px;
    border-radius: 20px;
    max-width: 60%;
    font-size: 1rem;
}

.message.received .message-content {
    background-color: black;
    color: white;
    border: 1px solid white;
}

.message.sent .message-content {
    background-color: #cecece;
    color: black;
}

.message.received {
    margin-left: 50px;
    align-items: flex-start;
    justify-content: flex-start;
}

.message.sent {
    align-items: flex-end;
    justify-content: flex-end;
}

.message-date {
    text-align: center;
    font-size: 0.8rem;
    color: #888;
    margin-bottom: 10px;
}

.message-input {
    display: flex;
    position: sticky;
    bottom: 0;
    padding: 5px;
    z-index: 100;
    align-items: center;
    background-color: black(102, 102, 102);
}

.message-input input {
    flex: 1;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #ddd;
    text-align: start;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    background-color: white;
    font-size: 1rem;
}

.message-input button {
    background-color: white;
    color: black;
    padding: 15px 17px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
}

.send-message-icon {
    margin-top: 4px;
}

.message-input button:hover {
    background-color: #bdbdbd;
}

.sender-info {
    display: flex;
    align-items: center;
    position: absolute; /* Position it absolutely within the message */
    bottom: 2px; /* Align to the bottom of the message */
    left: -50px; /* Adjust left position to align with the left side of the message */
}


.message-profile-pic-left {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.message-profile-pic-left-placeholder {
    font-size: 65px !important;
    border-radius: 50%;
    color: white;
    margin-bottom: -8px !important;
    margin-left: -10px !important;
}

@media (max-width: 768px) {
    .message-header {
        padding: 25px 10px;
    }



}
