.terms-conditions-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: black;
  }
  
  .terms-conditions-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
    color: #000;
  }
  
  .terms-conditions-date {
    text-align: center;
    font-size: 0.9rem;
    color: #666;
  }
  
  .terms-conditions-content h2 {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #000;
  }
  
  .terms-conditions-content h3 {
    margin-top: 10px;
    font-size: 1.2rem;
    color: black;
  }
  
  .terms-conditions-content p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .terms-conditions-content ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .terms-conditions-content ul li {
    margin-bottom: 10px;
  }
  
  .terms-conditions-content strong {
    color: #000;
  }
  