.activity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 600px;
    padding-bottom: 20px;
    position: relative;
    background: #000000;
    color: white;
    border-radius: 8px;
    box-shadow: 0 10px 30px 10px rgba(255, 255, 255, 0.1);
}

.activity-description-container {
    text-align: center; /* Centers the content horizontally */
    margin-top: 20px; /* Adds some space at the top */
}

.activity-error-message {
    color: red;
}

.activity-description-header {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px; /* Adds space between the header and description */
}

.activity-description-text {
    color: white;
    text-align: start;
    max-width: 500px;
    font-size: 1rem;
    margin: 0 auto; /* Centers the text horizontally */
}

.activity-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 400px; /* Prevents excessive stretching */
}

.activity-details p {
    display: flex;
    justify-content: space-between;
    width: 300px;
    color: white;
}

.button-group-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.activity-heading {
    color: white;
    font-weight: bold;
}

.activity-subheading {
    margin-top: 60px;
    color: white;
    font-weight: bold;
}

.activity-text {
    color: white;
    font-size: 1rem;
    margin-top: -8px;
}

.report-info {
    color: red;
}

.report-info a {
    color: white;
    text-decoration: underline;
}

.activity-text-price {
    color: white;
    font-size: 1rem;
    margin-top: -8px;
    margin-bottom: -8px;
}

.activity-text-details {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures vertical alignment */
    width: 100%;
    color: white;
}

.activity-text-cancel {
    color: white;
    font-size: 0.8rem;
}

.activity-text span {
    flex-grow: 1; /* Allows the description to take up available space */
    word-wrap: break-word; /* Ensures long words wrap properly */
    overflow-wrap: break-word;
}

.activity-profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.activity-profile-photo-placeholder {
    margin-top: 20px;
    margin-bottom: 10px;
    transform: scale(3);
    color: white;
}

.activity-button {
    display: flex;
    font-size: 13px;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.activity-button-host {
    padding: 15px 40px;
    font-size: 1.25rem;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.activity-button-primary {
    background-color: white;
    color: black;
    border: none;
}
.back-to-activities-button {
    color: white;
    margin-right: 490px;
    margin-top: 30px;
    
}
.activity-button-primary:hover {
    background-color: rgba(255, 255, 255, 0.817);
}

.activity-button-secondary {
    background-color: black;
    color: white;
    border: 2px solid white;
}

.activity-button-secondary:hover {
    background-color: rgb(27, 27, 27);
}

.activity-link-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: rgb(0, 119, 255);
    text-decoration: underline;
    cursor: pointer;
}

.activity-button {
    margin-top: 5px;
}

.activity-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(187, 187, 187, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .activity-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cancellation-reason-textarea {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .activity-cancel-submit-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 1rem;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .activity-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .star-container {
    display: flex;
    justify-content: center;
    margin: 5px 0px;
    margin-bottom: 20px;
  }
  
  .star {
    font-size: 30px;
    cursor: pointer;
    color: rgb(158, 158, 158);
    padding: 0px 10px;
    margin: 2px;
  }
  
  .star.selected {
    background-color: white;
    color: black;
  }
  
  
  @media screen and (max-width: 768px) {
    .activity-container {
        margin-top: 0px;
        margin-bottom: 20px;
        max-width: 375px;
        padding-bottom: 0px;
        border-radius: 0px;
        box-shadow: none;
    }

    .back-to-activities-button {
        margin-right: 305px;
        margin-top: 0px;
        padding-top: 5px;
        
    }

    .activity-description-text {
        color: white;
        text-align: start;
        max-width: 350px;
    }

    .activity-heading {
        font-size: 1.7rem;
    }

  }