.edit-listing-header {
    color: white;
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 20px;
}

.edit-photos-section {
    text-align: center;
    color: white;
    margin-top: 20px;
}

.edit-subtext {
    padding-top: 15px;
    font-size: 1rem;
    margin: 5px 0;
    color: #cccccc;
}

.edit-profile-photo-upload {
    position: relative;
    display: inline-block;
    margin: 20px 0;
    margin-bottom: 30px;
}

.edit-photo-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin: 0 auto;
    overflow: hidden !important;
}

.edit-photo-icon {
    transform: scale(1.8);
    color: white;
}

.edit-profile-photo-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-upload-button {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    border-radius: 15px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.edit-upload-button:hover {
    opacity: 0.95;
}

.edit-upload-button .camera-icon {
    font-size: 16px;
    margin-right: 5px;
}

.edit-upload-button input[type="file"] {
    display: none;
}

.edit-listing-photos-upload {
    margin: 20px 0;
    text-align: center;
}

.edit-upload-button-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    user-select: none;
}

.edit-upload-button-label:hover {
    opacity: 0.8;
}

.edit-image-upload-input {
    display: none;
}

.edit-file-count {
    font-size: 0.9rem;
    margin-top: 10px;
    color: #666666;
}

.edit-upload-instructions {
    font-size: 0.8rem;
    margin: 10px 0;
    color: #cccccc;
}

.edit-listing-photos-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.edit-photo-preview {
    position: relative;
    width: 200px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;
    background-color: black;
}

.edit-photo-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.need-stripe-warning {
    color: red;
    margin-top: -8px;
}

.edit-submit-button {
    border: none;
    padding: 20px 40px;
    font-weight: bold;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 50px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.edit-submit-button:hover {
    opacity: 0.8;
}

.edit-submit-button:disabled {
    background-color: #cccccc;
    /* Greyed-out background */
    cursor: not-allowed;
    /* Prevent hover effect and show a disabled cursor */
    opacity: 0.7;
    /* Slightly dim to indicate disabled state */
    pointer-events: none;
    /* Fully disables hover or click events */
}

.edit-form-section {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.edit-form-fields {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center inputs */
    justify-content: center;
    gap: 15px; /* Space between inputs */
    width: 100%;
}


.edit-city-select-ab {
    width: 100%; /* Consistent width across inputs */

}

.hourly-rate {
    width: 20%;
}

.edit-form-header {
    color: white;
    text-align: center;
    margin-bottom: 35px;
}

.edit-checkbox-container {
    padding-left: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Custom styling for the checkbox group */
.edit-checkbox-group {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align checkboxes left */
    color: white;
}

.edit-checkbox-group .MuiCheckbox-root {
    color: white; /* Ensures checkbox is visible */
}

.edit-checkbox-group .Mui-checked {
    color: white; /* Keeps the checkbox white when checked */
}

.edit-submit-button {
    border: none;
    padding: 20px 40px;
    font-weight: bold;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
    background-color: white;
    color: black;
    cursor: pointer;
  }
  
  .edit-submit-button:hover {
    opacity: 0.8;
  }
  
  .edit-submit-button:disabled {
    background-color: #cccccc; /* Greyed-out background */
    cursor: not-allowed; /* Prevent hover effect and show a disabled cursor */
    opacity: 0.7; /* Slightly dim to indicate disabled state */
    pointer-events: none; /* Fully disables hover or click events */
  }

  .edit-input-field:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
  }
  
  .edit-select-form-control-ab {
    max-width: 21%;
    background-color: black;
  
  }
  
  .edit-input-field {
    border-radius: 0;
    overflow: hidden;
    font-size: 13.5px;
    opacity: 0.9;
  }

  .edit-city-select-ab .MuiFormLabel-root {
    font-size: 14px !important;   /* Adjust font size */
    opacity: 0.5 !important;     /* Reduce opacity */
  }
  
  .edit-city-select-ab .MuiSelect-select {
    font-size: 13px !important;
    margin-left: 20px !important;
  }
  
  .edit-city-select-ab .MuiInputBase-root {
    margin-top: 5px;  /* Moves the input field up */
  }
  
  .edit-city-select-ab {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    font-size: 1rem;
    background-color: transparent;
  
  }
  
  
  .edit-city-select-ab .MuiSelect-icon {
    color: white; /* Dropdown arrow color */
  }
  
  
  .edit-city-select-ab .MuiOutlinedInput-root {
    background-color: transparent;
    border: 0.01mm solid white;
    border-top: none;
    border-right: none;
    border-left: none;
    padding: 1px;
    outline: none;
    box-shadow: none;
    text-align: center;
    color: white;
    width: 100%;
    
  }

  .edit-more-cities-text {
    font-size: 13px;
    opacity: 0.8;
    margin-top: -9px;
  }

  .edit-description-guidance {
    padding-top: 45px;
    padding-bottom: 25px;
    color: white;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .description-input {
    width: 40%;              
    height: 150px;           
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    resize: none;         
  }
  .edit-form-divider {
    height: 0.1px;
    background-color: white;
    width: 65%; /* Adjust width as needed */
    margin: 30px auto; /* Centering */
    border-radius: 0.5px;
  }

  
  .edit-description-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%; /* Ensures it only takes the text area's width */
  }
  
  .edit-char-counter {
    position: absolute;
    bottom: -10px; /* Moves it below the text area */
    
    font-size: 13px;
    color: gray;
  }
  
  

  @media screen and (max-width: 768px) {
    .edit-listing-header{
        font-size: 1.6rem;
    }
    .hourly-rate {
        width: 60%;
    }

    .edit-listing-subheader {
        font-size: 1.3rem;
    }

    .edit-select-form-control-ab {
        max-width: 66%;
    }

    .edit-description-guidance {
        max-width: 300px;
      }

    .description-input {
        width: 80%;
      }

      .edit-checkbox-group {
        text-align: left;
        gap: 10px;
      }
      .edit-form-divider {
        width: 90%;
      }

      .edit-checkbox-container {
        width: 85%;
    }

      }