/* General container styling */
.messages-container {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

/* Header styling */
.messages-header {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 15px 0;
    background-color: black;
    color: white;
}

/* Message threads container */
.messages-thread-list {
    width: 90%;
    max-width: 600px;
    margin-top: 20px;
}

/* Each message thread */
.messages-thread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #111;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    position: relative;
}

.messages-thread:hover {
    background-color: #222;
}

/* Profile picture */
.messages-profile-pic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.messages-profile-icon {
    font-size: 60px !important;
    color: white;
}

/* Thread info section */
.messages-thread-info {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

/* Name and last message */
.messages-thread-details {
    margin-left: 15px;
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.unread-indicator {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 8px;
}

.unread {
    font-weight: bold;
    color: white;
}

.messages-thread-name {
    font-size: 16px;
    font-weight: bold;
}

.messages-thread-last {
    font-size: 14px;
    color: #aaa;
}

/* Time or date on the right */
.messages-thread-time {
    font-size: 14px;
    color: #bbb;
}
