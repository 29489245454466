/* Container */
.become-ab-container {
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  /* Header Section */
  header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  input, textarea {
    border: none;
    outline: none;
}

input::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

a {
  color: white;
}


select:focus {
  outline-offset: 0px;
}

  .ab-h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.4rem;
  }
  
  header p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  header button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .start-app-button {
    margin-top: 30px;
    background: white;
    color: black;
    border-radius: 15px;
  }
  
  header button:hover {
    opacity: 0.8;
  }
  
  /* Cards */
  .cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
  }
  
  .card {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 200px;
  }
  
  .card .emoji {
    font-size: 2rem;
    margin-top: 10px;
  }
  
  /* How It Works */
  ol {
    text-align: left;           /* Centers the text */
    align-items: center;
    align-self: center;

    margin: 0 auto 30px;         /* Centers the list on the page */
    font-size: 1rem;           /* Increases font size */
}

li {
    font-size: 1rem;           /* Increases font size for list items */
    margin-bottom: 10px;         /* Adds space between list items */
    align-self: center;
    align-items: center;
}

.how-it-works-bullets {
    display: flex;
    max-width: 700px;

    margin: 0 auto;
}

.how-it-works-header {
    padding-top: 15px;
}
  
  /* Form Section */
  #application-form h2 {
    margin-top: 40px;
  }
  
  .form-section {
    margin: 40px 0;
  }
  
  input, textarea {
    background-color: black;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    width: 30%;
    padding: 10px;
    margin: 10px 0;
    display: 'block';
  }

  input {
    width: 20%;
    align-items: center;
    text-align: center;
  }

  

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center inputs */
    gap: 15px; /* Space between inputs */
}

.city-select-ab .MuiFormLabel-root {
  font-size: 14px !important;   /* Adjust font size */
  opacity: 0.5 !important;     /* Reduce opacity */
}

.city-select-ab .MuiSelect-select {
  font-size: 13px !important;
  margin-left: 20px !important;
}

.city-select-ab .MuiInputBase-root {
  margin-top: 5px;  /* Moves the input field up */
}

.city-select-ab {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-size: 1rem;
  background-color: transparent;

}


.city-select-ab .MuiSelect-icon {
  color: white; /* Dropdown arrow color */
}


.city-select-ab .MuiOutlinedInput-root {
  background-color: transparent;
  border: 0.01mm solid white;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 1px;
  outline: none;
  box-shadow: none;
  text-align: center;
  color: white;
  width: 100%;
  
}

.input-field:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #00000000;
}

.select-form-control-ab {
  max-width: 21%;
  background-color: black;

}

.input-field {
  border-radius: 0;
  overflow: hidden;
  font-size: 13.5px;
  opacity: 0.9;
}

.select-form-control-ab .MuiOutlinedInput-root {
  border-radius: 0; /* Match this to your input styling */
  overflow: hidden;
}

.more-cities-text {
  font-size: 13px;
  opacity: 0.8;
  margin-top: -9px;
}
  
  
  input.hourly-rate::placeholder {
    color: gray;
  }
  
  textarea {
    border: 1px solid white;
    height: 100px;
    resize: none;
  }

  .description-guidance {
    padding-top: 45px;
    padding-bottom: 25px;

    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
/* Centering the header */
.form-header {
    color: white;
    text-align: center;
    margin-bottom: 35px;
}

/* Aligning the checkboxes with some left padding and center the form */
.checkbox-container {
    padding-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Custom styling for the checkbox group */
.checkbox-group {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align checkboxes left */
    color: white;
}

.checkbox-group .MuiCheckbox-root {
    color: white; /* Ensures checkbox is visible */
}

.checkbox-group .Mui-checked {
    color: white; /* Keeps the checkbox white when checked */
}

.terms-section .MuiCheckbox-root {
  color: white; /* Ensures checkbox is visible */
}

.terms-section .Mui-checked {
  color: white; /* Keeps the checkbox white when checked */
}

.photos-section {
    text-align: center;
    color: white;
    margin-top: 20px;
}

.subtext {
    padding-top: 15px;
    font-size: 1rem;
    margin: 5px 0;
    color: #cccccc;
}

.profile-photo-upload {
    position: relative;
    display: inline-block;
    margin: 20px 0;
    margin-bottom: 30px;
}

.photo-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin: 0 auto;
    overflow: hidden;
}

.photo-icon {
    transform: scale(1.8);
    color: white;
}

.profile-photo-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upload-button {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    border-radius: 15px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.upload-button:hover {
    opacity: 0.95;
  }

.upload-button .camera-icon {
    font-size: 16px;
    margin-right: 5px;
}

.upload-button input[type="file"] {
    display: none;
}

.listing-photos-upload {
    margin: 20px 0;
    text-align: center;
}

.upload-button-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    user-select: none;
}

.upload-button-label:hover {
    opacity: 0.8;
  }

.image-upload-input {
    display: none;
}

.file-count {
    font-size: 0.9rem;
    margin-top: 10px;
    color: #666666;
}

.upload-instructions {
    font-size: 0.8rem;
    margin: 10px 0;
    color: #cccccc;
}

.listing-photos-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.photo-preview {
    position: relative;
    width: 200px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;
    background-color: black;
}

.photo-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.submit-button {
  border: none;
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.2rem;
  margin-top: 20px;
  background-color: white;
  color: black;
  cursor: pointer;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button:disabled {
  background-color: #cccccc; /* Greyed-out background */
  cursor: not-allowed; /* Prevent hover effect and show a disabled cursor */
  opacity: 0.7; /* Slightly dim to indicate disabled state */
  pointer-events: none; /* Fully disables hover or click events */
}

.form-divider {
  height: 0.1px;
  background-color: white;
  width: 80%; /* Adjust width as needed */
  margin: 60px auto; /* Centering */
  border-radius: 0.5px;
}

.error-messages {
  color: red;
  margin-bottom: 20px;
}

.error {
  margin: 5px 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White with some opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  border-radius: 10px;
}

.modal-content h2 {
  color: black;
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-content p {
  color: black;
  font-size: 16px;
  margin-bottom: 30px;
}

.go-home-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.go-home-button:hover {
  background-color: #444;
}

.ab-description-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures it only takes the text area's width */
}

.char-counter {
  position: absolute;
  bottom: -10px; /* Moves it below the text area */
  
  font-size: 13px;
  color: gray;
}


@media screen and (max-width: 768px) {

  header h1 {
    font-size: 2.1rem;
    width: 80%;
    margin: 0 auto;
  }

  .ab-h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  .become-ab-container {
    padding: 20px;
  }

  #application-form h2 {
    margin-top: 20px;
  }
  input {
    width: 60%;
  }

  textarea {
    width: 80%;
  }

  .description-guidance {
    max-width: 300px;
  }

  .checkbox-container {
    padding-left: 15px;
    width: 99%;
}
.checkbox-group .MuiFormControlLabel-label {
  font-size: 14px; 

}

.terms-section .MuiFormControlLabel-label {
  font-size: 14px;
}

.terms-section {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.form-divider {
  width: 90%; /* Adjust width as needed */
  margin: 40px auto; /* Centering */
}

.checkbox-group {
  text-align: left;
  gap: 10px;
}

.submit-button {
  padding: 18px 30px;
  font-size: 1rem;
}

.city-select-ab .MuiOutlinedInput-root {
  border: 0.29mm solid white;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 1px;
  outline: none;
  box-shadow: none;
  text-align: center;
  color: white;
  width: 100%;
  
}

.select-form-control-ab {
  max-width: 66%;
  background-color: black;

}

}